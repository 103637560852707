import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Backdrop, Container } from "./styles";
import { Fragment, useState } from "react";
import Sidebar from "../Sidebar";

const Header = () => {
   
  const [ sidebarOpen, setSidebarOpen ] = useState(false);

  return <Fragment>
    <Container className={sidebarOpen ? 'open' : ''}>
      <nav className="container-fluid d-flex justify-content-between align-items-center">
        <a role="button" onClick={() => setSidebarOpen(true)}>
          <FontAwesomeIcon icon={'bars'} color="white" />
        </a>
        <img className="brand-logo" src="/logo.png" />
        <span></span>
      </nav>
    </Container>
    <Sidebar open={sidebarOpen} onClose={()=>setSidebarOpen(false)}/>
    <Backdrop className={sidebarOpen ? 'active' : ''} />
  </Fragment>;
}

export default Header;