import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.aside`
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${colors.bgSecondary};
  box-shadow: 0px 0px 10px #00000011;
  padding: 1rem 0;
  z-index: 10;
  transition: left 0.2s ease-in-out;

  @media (max-width: 879px){
    left: -250px;

    &.open{
      left: 0px;
    }

    .close{
      display: block !important;
    }
  }
  
  .close{
    display: none;
    cursor: pointer;
    position: absolute;
    right: 1.3rem;
    top: 1.3rem;
  }

  .sidebar-title {
    font-weight: 700;
    color: #333333;
  }

  .sidebar-subtitle {
    margin-left: 1rem;
    color: #888888;
    font-size: 0.85rem;
  }

  .nav-link{
    color: #333;
    font-weight: 500;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;

    &.active{
      background-color: #dedede;
      color: ${colors.primaryColor};
      border-left: 4px solid ${colors.primaryColor};
    }

    &:hover{
      background-color: #cecece;
    }
    .icon{
      margin-right: 0.5rem;
    }
  }

  .brand-logo{
    width: 80px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

`;

