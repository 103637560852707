import { Fragment, useEffect } from "react";
import { Container, MainContainer } from "./styles";
import Header from "../../components/Header";
import { Outlet } from "react-router-dom";


const Main = () => {
  
  return (
    <Container>
      <Header/>
      <MainContainer>
        <Outlet />
      </MainContainer>
    </Container>
  );
}

export default Main;