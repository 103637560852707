import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router";
import IconButton from "../../../components/IconButton";
import { Section } from "../../../components/Section/styles";
import Spinner from "../../../components/Spinner";


const Users = () => {

  const [ users, setUsers ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ page, setPage ] = useState(1);
  const [ columns, setColumns ] = useState([]);

  const navigate = useNavigate();

  useEffect(() =>{
    setColumns([
      { title: 'Nome', key: 'name'},
      { title: 'E-mail', key: 'email'},
      { title: 'Nível', key: 'level'},
    ]);
    fetchData();
  }, []);

  const fetchData = async (sortBy) => {
    setLoading(true);
    try{
      const sortObj = sortBy ? { sort: sortBy } : {};
      const { data } = await api.get('users', {
        params: {
          ...sortObj
        }
      });

      setUsers(data);
    }catch(err){
      console.log(err);
    }
    setLoading(false);
  }

  const deleteUser = async (id) => {
    try{
      if(window.confirm('Deseja apagar esse usuário?') == true){
        await api.delete('/users', {
          params:{
            id
          }
        });
        fetchData();
      }
    }catch(e){
      console.log(e);
    }
  }


  return <Container>
    <div className="d-flex align-items-center gap-3">
      <h4 className="section-title">Usuários</h4>
        <IconButton inverted className={'rounded-circle mb-2'} onClick={()=>navigate('/usuarios/novo')}>
          <FontAwesomeIcon icon={'plus'} />
        </IconButton>
    </div>

    <Section className="mt-3">
    {loading ?
      <Spinner/>
      :
      (users.length ?
        <DataTable
          className="mt-3"
          columns={columns}
          setColumns={setColumns}
          onSort={fetchData}
          page={page}
          onChangePage={setPage}
          data={users}
          actions={(item) =>
            <span className="d-flex gap-1 align-items-center">
              <Button onClick={()=>deleteUser(item.id)}>
                <FontAwesomeIcon icon={'trash'} />
              </Button>
            </span>
          }
        />
      :
      <div className="text-center">
        <FontAwesomeIcon icon={'users'} fontSize={34} color={colors.muted} />
        <h6 className="mt-3"><b>Nenhum usuário cadastrado</b></h6>
      </div>
      )
      }
    </Section>



  </Container>
}

export default Users;