import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.div`
  label{
    font-weight: 600;
    color: ${colors.primaryColor}
  }
  
  .form-control{
    &:focus{
      border: ${colors.primaryColor};
      box-shadow: 0 0 0 0.25rem ${colors.primaryColor}33;
    }
  }

`;