import styled from "styled-components";
import { colors } from "../../../constants/colors";


export const Container = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.bgSecondary};

  .login-container{
    max-width: 400px;
    box-shadow: 0px 5px 15px #00000011;
  }
`;