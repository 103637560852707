import styled from "styled-components";
import { colors } from "../../constants/colors";


export const ButtonContainer = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform .1s ease-in-out;

  &:hover{
    color: ${colors.primaryColor};
    transform: scale(1.1);
  }

  ${props => props.inverted ? `
    background-color: ${colors.primaryColor};
    color: white;
    &:hover{
      color: white;
    }
  ` 
  : ''}

`;