import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styles";


const InputSearch = ({ onSubmitSearch, search, onChange, ...props}) => {

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    onSubmitSearch();
  }

  return <Container {...props}>
    <form onSubmit={handleSubmitSearch}>
      <div className="my-2 search-group col-md-6 col-12">
        <input value={search} onChange={e => onChange(e.target.value)} className="search-input" placeholder="Buscar..." />
        <button type="submit" className="btn-icon">
          <FontAwesomeIcon icon={'search'} fontSize={14} />
        </button>
      </div>
    </form>
  </Container>
}

export default InputSearch;