import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { InputGroup } from "../../../components/InputGroup";
import { useNavigate } from "react-router-dom";

const levelOps = [
  {title: 'Administrador', value: '1'},
  {title: 'Gerenciar pontos de coleta', value: '2'}
];

const EditUser = () => {

  const [ name, setName ] = useState('');
  const [ email, setEmail ] = useState('');
  const [ level, setLevel ] = useState('1');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      
      await api.post('/users', {
        name,
        email,
        level,
        password
      });
      navigate('/usuarios');
    }catch(e){
      console.log(e);
    }
    setLoading(false);

  }

  return <Container>
    <h4 className="section-title">Adicionar usuário</h4>

    <form onSubmit={onSubmit} className="mt-3">

      <InputGroup
        label={'Nome'}
        value={name}
        required
        onChange={setName}
        placeholder={'Insira o nome'}
      />
      <InputGroup
        label={'E-mail'}
        value={email}
        required
        onChange={setEmail}
        placeholder={'Insira o e-mail'}
      />
      <InputGroup
        label={'Nível'}
        value={level}
        required
        type={'select'}
        options={levelOps}
        onChange={(v) => (console.log(v),setLevel(v))}
      />
      <InputGroup
        label={'Senha'}
        type={'password'}
        value={password}
        required
        onChange={setPassword}
        placeholder={'Senha'}
      />
      <Button type="submit" loading={loading} loadingText={'Salvando...'} className="btn-primary mt-3">Salvar</Button>
    </form>
  </Container>
}

export default EditUser;