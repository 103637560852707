import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styles";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import IconButton from "../IconButton";
import { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';


const Sidebar  = ({open, onClose}) => {

  const [ level, setLevel ] = useState("1");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if(token){
      const decoded = jwtDecode(token);
      setLevel(decoded.level);
    }
  }, []);

  const location = useLocation();

  const logout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  }

  return <Container className={open ? 'open' : ''}>
    
    <FontAwesomeIcon onClick={onClose} className={'close'} icon={'close'} />


    <div className="text-center">
      <img className="brand-logo" src="/logo_full.png" />
    </div>

    <hr className="mx-3"/>
    <h6 className="sidebar-subtitle">Painel de controle</h6>

    <div className="mt-4">
      <NavLink to={'/pontos-de-coleta'} className={"nav-link"} active={location.pathname.startsWith('/pontos-de-coleta')}>
        <FontAwesomeIcon className={'icon'} icon={'location-dot'} />
        <span>Pontos de coleta</span>
      </NavLink>
      {level === "1" && <NavLink to={'/usuarios'} className="nav-link" active={location.pathname.startsWith('/usuarios')}>
        <FontAwesomeIcon className={'icon'} icon={'user'} />
        <span>Usuários</span>
      </NavLink>}

      <NavLink to={'/login'} onClick={() => logout()} className="nav-link mt-5" active={"0"}>
        <FontAwesomeIcon className={'icon'} icon={'power-off'} />
        <span>Sair</span>
      </NavLink>


    </div>

  </Container>;
} 

export default Sidebar;