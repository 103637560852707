import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { useNavigate } from "react-router-dom";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Section } from "../../../components/Section/styles";
import Spinner from "../../../components/Spinner";
import InputButton from "../../../components/IconButton";
import moment from 'moment';
import InputSearch from "../../../components/InputSearch";

const Point = () => {

  const [ page, setPage ] = useState(1);
  const [ points, setPoints ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ exportAllLoading, setExportAllLoading ] = useState(false);
  const [ deleteSelectedLoading, setDeletedSdeleteSelectedLoading ] = useState(false);
  const [ columns, setColumns ] = useState([]);
  const [ lastUpdate, setLastUpdate ] = useState();
  const [ search, setSearch ] = useState('');

  const navigate = useNavigate();

  useEffect(() =>{
    setColumns([
      { title: '#', key: '#', render: renderSelections},
      { title: 'Ponto de coleta', key: 'name', render: (item) => (<>
        <b>
          {item.is_top_selling && <FontAwesomeIcon color="red" icon={'medal'} />}
          &nbsp;
          {item.name}
        </b>
      </>)},
      { title: 'UF', key: 'uf'},
      { title: 'Cidade', key: 'city'},
      { title: 'Nome da farmácia', key: 'stablishmentName', sortKey: 'Stablishment.name', render: (item) => (<>
        <b>
          {item.is_top_selling && <FontAwesomeIcon color="red" icon={'star'} />}
          &nbsp;
          {item.stablishmentName}
        </b>
      </>)},
      { title: 'Distância', key: 'distance', sortKey: 'Stablishment.distance'},
      { title: 'Criado em', key: 'createdAt', sortKey: 'Stablishment.createdAt', render: (item) => moment(item.createdAt).format('DD/MM/YYYY HH:mm')},
      { title: 'Status', key: 'status', sortKey: 'Stablishment.status'},
    ]);

    fetchUpdated();
    
  }, []);

  const searchDebounce = useRef();

  useEffect(() => {
    
    if(searchDebounce.current) clearTimeout(searchDebounce.current);
    searchDebounce.current = setTimeout(() =>{
      fetchData();
    }, 300);

  }, [ search ]);

  const fetchUpdated = async () => {
    const { data } = await api.get('stablishments/last-update');
    setLastUpdate(data.lastUpdate);
  }

  const fetchData = async (sortBy = null) => {
    setLoading(true);
    const sortObj = sortBy ? { sort: sortBy } : {};
    try{
      setPage(1);
      const { data } = await api.get('points', {
        params:{
          userid: 22,
          search,
          ...sortObj
        }
      });

      setPoints(data);
    }catch(err){
      console.log(err);
    }
    setLoading(false);
  }
  
  // const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  // const fileExtension = '.xlsx';

  const getProducts = async (id) => {
    try{
      const { data } = await api.get(`products/export?id=${id}`);

      return data;
    }catch(err){
      console.log(err);
    }
    return [];
  }

  const getAllProducts = async () => {
    try{
      const { data } = await api.get(`products/all`);

      return data;
    }catch(err){
      console.log(err);
    }
    return [];
  }

  

  const exportToCSV = async (item) => {

    setPoints(prev => {
      const up = [ ...prev ];
      up.find(p => p.stablishmentId === item.stablishmentId).loadingExport = true;
      return up;
    })

    try{
      const products = await getProducts(item.stablishmentId);
  
      if(!products.length){
        window.alert('Nenhum produto nessa farmácia');
        throw new Error('Nenhum produto nessa farmácia');
      }

      let csvData = products.map(obj => '"' + Object.values(obj).join('";"') + '"');
      csvData.unshift(Object.keys(products[0]).join(';'));
      const blob = new Blob([`\uFEFF${csvData.join('\n')}`], { type: 'text/csv;charset=utf-8;' });
      const csvURL = URL.createObjectURL(blob);
    
  
      // const ws = XLSX.utils.json_to_sheet(products);
      // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'  });
      // const data = new Blob([excelBuffer], {type: fileType});
      FileSaver.saveAs(csvURL, item.stablishmentName + '.csv');
    }catch(e){
      console.log(e);
    }

    setPoints(prev => {
      const up = [ ...prev ];
      up.find(p => p.stablishmentId === item.stablishmentId).loadingExport = false;
      return up;
    })
  }

  const exportAll = async () => {
    setExportAllLoading(true);
    const products = await getProducts((points.some(p => p?.selected) ? points.filter(p => p?.selected) : points).map((p) => p.stablishmentId).join(','));

    if(!products.length){
      window.alert('Nenhum ponto de coleta');
      setExportAllLoading(false);
      return;
    }

    let csvData = products.map(obj => '"' + Object.values(obj).join('";"') + '"');
    csvData.unshift(Object.keys(products[0]).join(';'));
    const blob = new Blob([`\uFEFF${csvData.join('\n')}`], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(blob);
    FileSaver.saveAs(csvURL, 'Pontos de coleta.csv');
    setExportAllLoading(false);

  }

  const deleteStablishment = async (id) => {
    try{
      if(window.confirm('Deseja apagar essa farmácia?') == true){
        await api.delete('/stablishments', {
          params:{
            id
          }
        });
        fetchData();
      }
    }catch(e){
      console.log(e);
    }
  }

  const selectAll = () => {
    setPoints(prev => {
      if(prev.every(p => p.selected)){
        return prev.map(p => ({...p, selected: false}));
      }else{
        return prev.map(p => ({...p, selected: true}));
      }
    })
  }

  const deleteSelected = async () => {
    const selected = points.filter(p => p.selected).map(p => p.stablishmentId).join(',')
    
    if(!selected){
       return
    }

    await deleteStablishment(selected);
  }

  const handleSelection = (stablishmentId, checked) => {
    setPoints(prev => {
      const up = [...prev];
      up.find(p => p.stablishmentId === stablishmentId).selected = checked
      return up;
    });
  }

  const renderSelections = (item) => {
    return <input type="checkbox" checked={item?.selected} onChange={(e)=>handleSelection(item.stablishmentId, e.target.checked)} />
  }

  return <Container>
    <div className="d-flex align-items-center gap-3">
      <h4 className="section-title">Pontos de coleta</h4>
        <InputButton inverted className={'rounded-circle mb-2'} onClick={()=>navigate('/pontos-de-coleta/novo')}>
          <FontAwesomeIcon icon={'plus'} />
        </InputButton>
    </div>
    {lastUpdate && <h6 className="last-update">Última atualização: {moment(lastUpdate).format('DD/MM/YYYY HH:mm')}</h6>}

    <Section className="mt-3">
      <InputSearch
        search={search}
        onChange={setSearch}
        onSubmitSearch={() => fetchData(null)}
      />
      {loading ?
      <Spinner/>
      :
      (points.length ?
        <DataTable
          className="mt-3"
          columns={columns}
          setColumns={setColumns}
          data={points}
          onSubmitSearch={(v) => fetchData()}
          topActions={[
            <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>selectAll()}>
              { 
                'Selecionar todos' 
              }
            </Button>,
            <Button loadingText={'Aguarde! Exportando...'} className={'btn-primary'} onClick={()=>exportAll()}>
              { 
                points.some(p => p.selected) ? 'Exportar ' + points.filter(p => p.selected).length + ' selecionados' : 'Exportar todos' 
              }
            </Button>,
            points.some(p => p.selected) && <Button loading={deleteSelectedLoading} style={{color: 'red'}} loadingText={'Aguarde! Excluindo...'} className={'btn-outline'} onClick={()=>deleteSelected()}>
              { 
                'Excluir ' + points.filter(p => p.selected).length + ' selecionados'
              }
            </Button>,
          ]}
          onSort={fetchData}
          page={page}
          onChangePage={setPage}
          actions={(item) =>
            <span className="d-flex gap-1 align-items-center">
              <Button loading={item.loadingExport} onClick={()=>exportToCSV(item)}>
                <FontAwesomeIcon icon={'download'} color={colors.primaryColor} />
              </Button>
              <Button onClick={()=>deleteStablishment(item.stablishmentId)}>
              <FontAwesomeIcon icon={'trash'} />
              </Button>
            </span>
          }
        />
      :
      <div className="text-center">
        <FontAwesomeIcon icon={'location-dot'} fontSize={34} color={colors.muted} />
        <h6 className="mt-3"><b>Nenhum ponto de coleta cadastrado</b></h6>
      </div>
      )
      }
    </Section>


  </Container>
}

export default Point;