import { useEffect, useState } from "react";
import Button from "../../../components/Button";
import DataTable from "../../../components/DataTable";
import { Container } from "./styles";
import api from "../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { colors } from "../../../constants/colors";
import { InputGroup } from "../../../components/InputGroup";
import { useNavigate, useNavigation } from "react-router-dom";
import axios from "axios";


const EditPoint = () => {

  const [ name, setName ] = useState('');
  const [ uf, setUf ] = useState('');
  const [ city, setCity ] = useState('');
  const [ coordinates, setCoordinates ] = useState('');
  const [ topSelling, setTopSelling ] = useState(false);
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      
      await api.post('/points', {
        name,
        city,
        uf,
        coordinates,
        top_selling: topSelling,
        id_user: 22
      });
      navigate('/');
    }catch(e){
      console.log(e);
    }
    setLoading(false);

  }

  return <Container>
    <h4 className="section-title">Criar ponto de coleta</h4>

    <form onSubmit={onSubmit} className="mt-3">

      <InputGroup
        label={'Nome'}
        value={name}
        onChange={setName}
        placeholder={'Insira o nome'}
      />
      <InputGroup
        label={'UF'}
        value={uf}
        onChange={setUf}
        placeholder={'Insira a UF'}
      />
      <InputGroup
        label={'Cidade'}
        value={city}
        onChange={setCity}
        placeholder={'Cidade'}
      />
      <InputGroup
        label={'Coordenadas (latitude,longitude)'}
        value={coordinates}
        onChange={setCoordinates}
        placeholder={'-23.5674843,-46.5696358'}
      />
      <InputGroup
        label={'Incluir mais vendidos'}
        type="checkbox"
        name="top_selling"
        value={topSelling}
        onChange={setTopSelling}
      />

      <Button type="submit" loading={loading} loadingText={'Aguarde! Importando farmácias...'} className="btn-primary mt-3">Salvar</Button>
    </form>
  </Container>
}

export default EditPoint;