import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.div`

  .search-group{
    display: flex;
    align-items: center;
    background: #fafafa;
    border-radius: 10px;
    padding: 7px 1rem;
    border: 1px solid #d5d4d6;
    gap: 1rem;

    .btn-icon{
      border-radius: 100%;
      border: none;
      background: ${colors.primaryColor};
      outline: none;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
    }

    .search-input{
      background: none;
      border-radius: none;
      padding: none;
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 0.85rem;
      flex: 1
    }
  }

`;