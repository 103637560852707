import styled from "styled-components";
import { colors } from "../../constants/colors";



export const Container = styled.header`

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: ${colors.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 880px) {
    display:none;
  }

  .brand{
    margin-bottom: 0;
    font-weight: 700;
    color: white;
  }

  .brand-logo{
    width: 35px;
    background-color: white;
    padding: 3px;
    border-radius: 100%;
    box-shadow: 0px 3px 10px #00000055;
  }

`;

export const Backdrop = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;
  background-color: #00000060;
  width: 100vh;
  height: 100vh;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  
  &.active{
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: 880px){
    display: none !important;
  }
`;