import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container } from "./styles";
import { useEffect, useState } from "react";

const DataTable = ({ data, actions, topActions, onSort, columns, setColumns, page = 1, onChangePage }) => {

  const [ pageRange, setPageRange ] = useState([(page-1) * 20, page*20]);

  const [ search, setSearch ] = useState('');

  useEffect(()=>{
    setPageRange([(page-1) * 20, page*20]);
  }, [page])

  const sortByColumn = (column) => {
    let sort = !column.sort ? 1 : column.sort + 1;
    sort = sort > 2 ? 0 : sort;
    
    setColumns(prev => {
      const _new = [ ...prev ];
      _new.forEach(c => {
        if(c.key === column.key){
          c.sort = sort;
        }else{
          c.sort = 0;
        }
      });

      return _new;
    })
    if(sort > 0){

      const sortBy = (sort === 2 ? '-' : '') + (column.sortKey || column.key);
      onSort(sortBy);

    }else{
      onSort();
    }
  }

  const renderPages = () => {
    const qtdPages = Math.ceil(data.length / 20);
    return <nav aria-label="Páginas">
      <ul className="pagination">
        <li className="page-item"><button disabled={page === 1} onClick={()=>onChangePage(page-1)} tabIndex="-1" className="page-link"><FontAwesomeIcon icon="chevron-left"/></button></li>
        {new Array(qtdPages).fill(0).map((_,i) =>{
          return <li key={i} className="page-item"><button tabIndex="-1" onClick={()=>onChangePage(i+1)} className={"page-link " + (page === i+1 ? 'active' : '') }>{i+1}</button></li>
        })}
        <li className="page-item"><button disabled={page === qtdPages} onClick={()=>onChangePage(page+1)} tabIndex="-1" className="page-link"><FontAwesomeIcon icon="chevron-right"/></button></li>
      </ul>
    </nav>
  }

  const renderColumns = () => {
    return columns.map((c, i) => <th role="button" key={c.key}><a role="button" onClick={()=>(onSort && c.key !== '#') && sortByColumn(c)} className="column">
        {c.title} {onSort && c.key !== '#' && (
        <span className="sort-buttons">
          {!c.sort || c?.sort == 0 ?
            <><span>▲</span><span>▼</span></>
          : (
            c?.sort == 1 ? '▲' : '▼'
          )}
        </span>)}
      </a></th>)
  }

  return <Container className="table-responsive">
    <div className="d-flex align-items-center pt-2 mb-3 gap-3">
      <p className="pagination-text mb-0">Mostrando <b>{pageRange[0] + 1}</b> - <b>{pageRange[1]}</b> de <b>{data.length}</b> itens</p>
      {topActions}
    </div>
    
    <table className="table table-striped table-small table-borderless">
      <thead>
        <tr>
          {renderColumns()}
          {actions && <th></th>}
        </tr>
      </thead>
      <tbody>
        {data.slice((page-1) * 20,page*20).map((item, i) =>{
          return <tr key={i}>
            {columns.map((c, i) => {
              return c.render ? <td key={i}>{c.render(item)}</td> : <td key={i}>{item[c.key]}</td>
            })}
            {actions && <td>{actions(item)}</td>}
          </tr>
        })}
      </tbody>
    </table>
    {renderPages()}
  </Container>
}

export default DataTable;