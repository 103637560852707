import { ButtonContainer } from "./styles";


const IconButton = (props) => {
  return <ButtonContainer inverted={props.inverted} disabled={props.loading} {...props} className={'btn ' + props.className }>{
    props.loading ?
    <div className="d-flex align-items-center">
      {props.loadingText && <span>{props.loadingText}&nbsp;</span>}
      <div className="cv-spinner">
        <span className="spinner"></span>
      </div>
    </div>
    : props.children
    }</ButtonContainer>
}

export default IconButton;