import styled from "styled-components";
import { colors } from "../../constants/colors";


export const Container = styled.div`
  .pagination-text{
    font-size: 0.85rem; 
  }

  .pagination{
    .page-link{
      &:focus,&:disabled{
        box-shadow: none;
        background-color: white;
      }
      color: black;

      &.active{
        background-color: ${colors.primaryColor};
        color: white;
      }

      &:disabled{
        color: ${colors.muted};
      }
    }
  }

  thead th{
    .column{
      text-decoration: none;
      color: ${colors.primaryColor};
      display: inline-flex;
      align-items: center;
      gap: 5px;
    }
    .sort-buttons{
      font-size: 7px;
      display: inline-flex;
      flex-direction: column;
      opacity: 0.3;
    }
  }

  tr{
    font-size: 0.85rem;
  }

  .table-striped>tbody>tr:nth-child(odd)>td, 
  .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: white;
    --bs-table-accent-bg: none;
  }
  .table-striped>tbody>tr:nth-child(even)>td, 
  .table-striped>tbody>tr:nth-child(even)>th {
    --bs-table-accent-bg: none;
    background-color: #f2efef;
  }

`;