import { useEffect, useLayoutEffect, useState } from "react";
import { Section } from "../../../components/Section/styles";
import { Container } from "./styles";
import { InputGroup } from "../../../components/InputGroup";
import Button from "../../../components/Button";
import api from "../../../services/api";
import { useNavigate } from "react-router";


const Login = () => {

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if(token){
      navigate('/');
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try{
      const { data } = await api.post('login', {
        email,
        password
      });

      if(data?.token){
        localStorage.setItem('access_token', data.token);
        navigate('/');
        return;
      }

      window.alert(data.message);
      
    }catch(e){
      console.log(e);
      window.alert(e.response.data.message);
    }
    setLoading(false);
  }

  return <Container>

    <Section className="col-10 login-container">
      <form method="POST" onSubmit={onSubmit}>
        <h4 className="section-title">Login</h4>
        <InputGroup
          label={'E-mail'}
          value={email}
          type={'email'}
          required
          onChange={setEmail}
          placeholder={'E-mail'}
        />
        <InputGroup
          label={'Senha'}
          type={'password'}
          value={password}
          required
          onChange={setPassword}
          placeholder={'Senha'}
        />

        <div className="text-center mt-4">
          <Button loading={loading} loadingText={'Entrando...'} type="submit" className={'btn-primary'}>
            Entrar
          </Button>
        </div>
      </form>
    </Section>
  </Container>

}

export default Login;