import axios from "axios";


const api = axios.create({
  baseURL: 'https://api-raio10x.xception.in/',
  // baseURL: 'http://localhost:3500/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Bypass-Tunnel-Reminder': '1'
  }
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use((response) => {
  return response;
},
(error) => {
  if (error.response && error.response.status === 401) {
    localStorage.removeItem('access_token');
    window.location.href = '/login';
  }

  return Promise.reject(error);
})

export default api;